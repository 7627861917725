import Vue from "vue"
import {
	Button,
	Icon,
	Tag,
	DropdownMenu,
	DropdownItem,
	Divider,
	Tabbar,
	Tab,
	Tabs,
	Overlay,
	TabbarItem,
	Col,
	Row,
	Grid,
	GridItem,
	Form,
	Field,
	Collapse,
	CollapseItem,
	GoodsAction,
	GoodsActionButton,
	GoodsActionIcon,
	Sku,
	SwipeCell,
	Card,
	Radio,
	RadioGroup,
	Checkbox,
	CheckboxGroup,
	SubmitBar,
	Stepper,
	Loading,
	Sticky,
	TreeSelect,
	NavBar,
	Cell,
	CellGroup,
	Progress,
	Image as VanImage,
	NoticeBar,
	Dialog,
	Empty,
	CountDown,
	ActionSheet,
	Popup, Search, IndexBar, IndexAnchor
} from "vant"
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Popup);
Vue.use(Search);
Vue.use(Progress);
Vue.use(TreeSelect)
Vue.use(Sticky)
Vue.use(Loading)
Vue.use(Stepper)
Vue.use(SubmitBar)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Card)
Vue.use(SwipeCell)
Vue.use(Sku)
Vue.use(GoodsAction)
Vue.use(GoodsActionButton)
Vue.use(GoodsActionIcon)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Form)
Vue.use(Field)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Col)
Vue.use(Row)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tag)
Vue.use(Icon)
Vue.use(Button)
Vue.use(NavBar);
Vue.use(NoticeBar);
Vue.use(Divider);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Overlay);
Vue.use(VanImage);
Vue.use(Dialog);
Vue.use(Empty);
Vue.use(CountDown);
Vue.use(ActionSheet);
