import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import "@/vantui"
import "reset-css"
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
import fengmap from "fengmap/build/fengmap.map.min"; //核心包
Vue.prototype.$fengmap = fengmap;
import './style/index.less'
// Vue.filter("RMBformat", (val) => {
// 	return "￥" + Number(val).toFixed(2) + "元"
// })
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app")
