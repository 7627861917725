<template>
  <div class="box">
    <div id="fengmap">
      <div
        class="layui-panel floor"
        id="floor"
      ></div>
    </div>
    <!-- 信息面板 -->
    <div class="panel-box">
      <span id="info">请设置起终点进行模拟导航</span>
    </div>
    <div class="bottom_box">
      <van-cell-group>
        <van-field
          @focus="focusInput('start')"
          v-model="value"
          readonly
          placeholder="请输入起点"
        >
          <template #left-icon>
            <van-icon
              name="location"
              color="#03ed17"
            />
          </template></van-field>
        <div class="der_box"></div>
        <van-field
          @focus="focusInput('dest')"
          v-model="value1"
          readonly
          placeholder="请输入终点"
          left-icon="location"
        >
          <template #left-icon>
            <van-icon
              name="location"
              color="red"
            />
          </template></van-field>
        <van-icon
          name="sort"
          class="sort_box"
        />
      </van-cell-group>
      <div class="btn-box flex">
        <van-button
          type="info"
          size="small"
          round
          @click="navigation"
          v-if="!routeStatus"
        >开始导航</van-button>
        <van-button
          type="default"
          size="small"
          round
          @click="quitNavigation"
          v-else
        >退出导航</van-button>
        <!-- <van-button
          type="info"
          size="small"
          round
          v-if="routeStatus"
          @click="cancelNavigation"
        >取消</van-button> -->
      </div>
    </div>
    <van-popup
      v-model="show"
      closeable
      position="bottom"
      :style="{ height: '90%' }"
    >
      <div class="pop-box flex">
        <div class="search_box">
          <van-search
            @input="changeBtn"
            placeholder="请输入起点名称"
          />
        </div>
        <div class="pop-content">
          <van-index-bar :index-list="indexList">
            <van-index-anchor index="F1" />
            <van-cell
              v-for="item in list2"
              :key="item.eid"
              :title="item.name"
              @click="selectBtn(item)"
            />

            <van-index-anchor index="B1" />
            <van-cell
              v-for="item in list1"
              :key="item.eid"
              :title="item.name"
              @click="selectBtn(item)"
            />
          </van-index-bar>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import fengmap from "fengmap/build/fengmap.map.min";
import "fengmap/build/fengmap.plugin.ui.min";
import "fengmap/build/fengmap.analyser.min.js";
import "fengmap/build/fengmap.plugin.navi.min"; //导航包
import "fengmap/build/fengmap.effect.min"; //特效包
import "fengmap/build/fengmap.plugin.markers.min"; //特殊标注包
import "fengmap/build/fengmap.plugin.draw.min"; //绘图包
import "fengmap/build/fengmap.plugin.location.min"; //位置服务相关功能包
import "fengmap/build/fengmap.plugin.export.min"; //打印/出图包
import "fengmap/build/fengmap.plugin.layers.min"; //附加图层包
var map;
var navi; // 导航对象
var analyser; // 路径分析对象
var floorInfo; // 楼层信息
// var start; // 起点
// var dest; // 终点
var clickCount = 0; // 地图点击次数
var activeBtn = "start"; // 选中按钮
var activeSpeed = "normal"; // 选中倍速按钮
var isPlaying = false;
var lineMarker; // 路径线
var domMarker; // 设置起点  设置终点弹窗
// 假设你有一个按钮元素，其id为"myButton"
export default {
  data() {
    return {
      simulateOptions: {
        speed: 7, // 模拟导航定位图标行进的速度, 单位m/s。默认7m/s。
        followPosition: true, // 模拟导航中在位置发生变化时, 地图是否跟随位置居中显示。默认true。
        followAngle: false, // 模拟导航中在方向发生变化时, 地图是否跟随方向变化, 保持路线朝向屏幕上方。 默认false。
        changeTiltAngle: true, // 模拟导航中楼层发生变化时是否改变地图的倾斜角度, 并执行动画。默认为true。
        zoom: 21, // 模拟导航开始时地图的显示缩放级别, 默认值为21, 表示1:282的地图比例尺。
        maxZoom: 22, // 模拟导航开始时地图的显示最大缩放级别, 默认值为22, 表示1:141的地图比例尺。防止视角过近。
      },
      isPlaying: false,
      value: "",
      value1: "",
      list: null,
      show: false,
      newResult: [],
      indexList: ["F1", "B1"],
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      start: {},
      dest: {},
      selectStatus: "",
      routeStatus: false,
      clickPoint: {},
    };
  },
  components: {},
  methods: {
    initMap() {
      var options = {
        container: document.getElementById("fengmap"),
        appName: "室内导航_ZXZX",
        key: "aaec95848c589df525fbfb753a57524b",
        mapID: "1763852661488877569",
        themeID: "",
        // viewMode: fengmap.FMViewMode.MODE_2D,
        level: 2,
        mapZoom: 22,
      };
      map = new fengmap.FMMap(options);
      console.log(map, fengmap);
      map.on("click", this.mapClick);
      map.on("loaded", this.initNav);
      map.on("loaded", this.initRoute);
      map.on("loaded", this.initList);
    },
    mapClick(e) {
     
      if (domMarker) {
        domMarker.remove();
        domMarker = null;
      }
      if( !e.coords.x || !e.coords.y) {
        return
      }
      console.log(e);
      this.clickPoint = e || {};
      domMarker = new fengmap.FMDomMarker({
        x: e.coords.x,
        y: e.coords.y,
        domHeight: 50,
        domWidth: 200,
        anchor: fengmap.FMMarkerAnchor.BOTTOM,
        content: `<div style=" display: inline-block; width: 200px; text-align: center; fontSize:.16rem;" >
        <div style="display: flex;margin-bottom: 10px; border-radius: 6px; background-color: white; text-align: center;">
            <span style="flex: 1; display: flex;
  justify-content: center;
  align-items: center; height: .5rem; color: white;  background-color: #2F65EE;
   border-bottom-left-radius: .06rem; border-top-left-radius:.06rem; fontSize:.14rem; z-index: 1000; cursor: pointer;" 
   onclick=setStart() ontouchstart=setStart() id="setStart">作为起点</span>
            <span style="flex: 1;  display: flex;
  justify-content: center;
  align-items: center; height: .5rem;  fontSize:.14rem;" onclick="setEnd()" ontouchstart=setEnd("")>作为终点</span>
        </div>
        <div style="background-image: url('https://developer.fengmap.com/fmAPI/images/navi/marker.png');display: inline-block; width: 32px; height: 32px;background-size:100%; "></div>
    </div>`,
      });
      var level = e.level;
      var floor = map.getFloor(level);
      domMarker.addTo(floor);
      console.log(domMarker);
    },
    setStart() {
      const model = this.clickPoint.targets.find(
        (item) => item.type === fengmap.FMType.MODEL
      );
      this.start = {
        level: map.getLevel(),
        x: model.x,
        y: model.y,
        url: "https://developer.fengmap.com/fmAPI/images/start.png",
        size: 32,
        height: this.clickPoint.coords.height,
      };
      this.value = model.name;
      navi.setStartPoint(this.start);
      console.log("start");
      domMarker?.remove();
      domMarker = null;
    },
    // 设置终点图标和信息
    setEnd() {
      console.log("end");
      const model = this.clickPoint.targets.find(
        (item) => item.type === fengmap.FMType.MODEL
      );
      this.dest = {
        level: map.getLevel(),
        x: model.x,
        y: model.y,
        url: "https://developer.fengmap.com/fmAPI/images/end.png",
        size: 32,
        height: this.clickPoint.coords.height,
      };
      this.value1 = model.name;
      navi.setDestPoint(this.dest);
      domMarker?.remove();
      domMarker = null;
    },
    initRoute() {
      // 初始化导航分析类
      analyser = new fengmap.FMNaviWalkAnalyser({ map: map }, function () {
        if (navi) {
          navi.clearAll();
        }
        navi = new fengmap.FMNavigationWalk({
          map: map,
          analyser: analyser,
          locationMarkerUrl:
            "https://developer.fengmap.com/fmAPI/images/bluedot-arrow.png",
          locationMarkerSize: 48,
          lineMarkerHeight: 0.5,
          locationMarkerHeight: 0.5,
        });
      });
      // 楼层信息
      floorInfo = map.getFloorInfos();
      var searchRequest = new fengmap.FMSearchRequest();
      searchRequest.type = fengmap.FMType.LABEL;
      console.log(floorInfo, searchRequest);
      // 设置起点所在楼层
      var startGname = this.getFloorNameByGid(map.getLevel());
      var floorDom = document.getElementById("floor");
      floorDom.innerHTML = startGname;
    },
    naviRoute() {
      let _this = this;
      // 导航分析
      navi.route(
        {
          mode: fengmap.FMNaviMode.MODULE_BEST,
          priority: fengmap.FMNaviPriority.PRIORITY_DEFAULT,
        },
        (result) => {
          console.log(result);
          // 导航分析成功回调
          navi.drawNaviLine();
          // var line = navi.drawNaviLine();
          // 自适应路线全览
          navi.overview(
            {
              ratio: 1.5,
            },
            () => {
              console.log("自适应全览动画结束回调");

              if (activeBtn === "start") {
                this.handleBtn("start");
              }
            }
          );
        },
        (result) => {
          // 导航分析失败回调
          console.log("failed", result);
        }
      );
      // 导航过程中位置发生变化时触发的事件, 模拟导航与真实导航调用locate后都都会触发。
      navi.on("walking", function (data) {
        // 更新路线信息
        _this.updateUI(data);
      });
      // 模拟导航结束事件, 真实导航不会触发该事件。需要开发者根据driving事件的返回内容, 进行业务处理。
      navi.on("complete", function (data) {
        isPlaying = false;
        var dom = document.getElementById("info");
        dom.innerHTML = `模拟导航结束！`;
      });
    },

    getFloorNameByGid(level) {
      var gname = floorInfo.find(function (item) {
        return item.level === level;
      }).name;
      return gname;
    },
    initNav() {
      // 楼层控件
      var scrollFloorCtlOpt = {
        position: fengmap.FMControlPosition.RIGHT_TOP,
        floorButtonCount: 5,
        offset: {
          x: -20,
          y: 150,
        },
        viewModeControl: true,
        floorModeControl: true,
        needAllLayerBtn: true,
      };
      let scrollFloorControl = new fengmap.FMToolbar(scrollFloorCtlOpt);
      scrollFloorControl.addTo(map);
    },
    cancelNavigation() {},
    quitNavigation() {
      this.routeStatus = false;
      this.value = "";
      this.value1 = "";
      this.handleBtn("init");
      navi.clearAll();
      var dom = document.getElementById("info");
      dom.innerHTML = `请设置起终点进行模拟导航`;
    },
    handleBtn(type) {
      if (type === "start" && isPlaying) return;
      switch (type) {
        case "init":
          // 初始化设置，先停止模拟导航
          navi.stop();
          isPlaying = false;
          break;
        case "start":
          // 开始模拟导航
          navi.simulate(this.simulateOptions);
          // 更改倍速
          // changeSpeed(activeSpeed);
          isPlaying = true;
          var dom = document.getElementById("info");
          dom.innerHTML = `开始模拟导航！`;
          break;
        case "pause":
          // 暂停模拟导航
          navi.pause();
          break;
        case "resume":
          // 恢复模拟导航
          navi.resume();
          break;
        case "stop":
          // 停止模拟导航
          navi.stop();
          isPlaying = false;
          this.naviRoute();
          break;
        default:
          break;
      }

      // 修改设置面板展示状态
      // var dom = document.getElementById("formPanel");
      // if (type === "init") {
      //   dom.classList.remove("hidden");
      // } else {
      //   dom.classList.add("hidden");
      // }
      // // 修改按钮选中状态
      // if (activeBtn) {
      //   var dom = document.getElementById(activeBtn);
      //   dom.classList.remove("layui-btn-primary");
      // }
      // var curDom = document.getElementById(type);
      // curDom.classList.add("layui-btn-primary");
      // activeBtn = type;
    },
    initList() {
      var searchRequest = new fengmap.FMSearchRequest();
      searchRequest.type = fengmap.FMType.MODEL;
      this.list = new fengmap.FMSearchAnalyser({ map: map }, () => {});
      this.list.query(searchRequest, (result) => {
        this.newResult = result.filter((item) => item.name !== undefined);
        console.log(this.newResult);
        this.$nextTick(() => {
          this.list1 = this.newResult.filter((item) => item.level == 1);
          this.list2 = this.newResult.filter((item) => item.level == 2);
          this.list3 = this.newResult.filter((item) => item.level == 1);
          this.list4 = this.newResult.filter((item) => item.level == 2);
        });
      });
    },
    focusInput(data) {
      this.selectStatus = data;
      this.show = true;
    },
    selectBtn(data) {
      console.log(data);
      if (this.selectStatus == "start") {
        this.start = {
          level: map.getLevel(),
          x: data.center.x,
          y: data.center.y,
          url: "https://developer.fengmap.com/fmAPI/images/start.png",
          size: 32,
          height: data.center.z,
        };
        this.value = data.name;
        navi.setStartPoint(this.start);
      } else if (this.selectStatus == "dest") {
        this.dest = {
          level: map.getLevel(),
          x: data.center.x,
          y: data.center.y,
          url: "https://developer.fengmap.com/fmAPI/images/end.png",
          size: 32,
          height: data.center.z,
        };
        this.value1 = data.name;
        navi.setDestPoint(this.dest);
      }
      this.show = false;
    },
    changeBtn(data) {
      if (!data) {
        this.list1 = this.list3;
        this.list2 = this.list4;
        return;
      }
      this.list1 = this.list3.filter((item) => {
        return item.name.toLowerCase().includes(data.toLowerCase());
      });
      this.list2 = this.list4.filter((item) => {
        return item.name.toLowerCase().includes(data.toLowerCase());
      });
    },
    navigation() {
      console.log("1111");
      this.routeStatus = true;
      // map.on("loaded", this.initRoute);
      this.naviRoute();
    },
    updateUI(data) {
      // 获取导航过程路线信息
      var instruction = navi.naviResult.subs[data.index].instruction.zh;
      // 剩余距离
      var remain = data.remain.toFixed(2);
      var dom = document.getElementById("info");
      dom.innerHTML = `${instruction}， 距离终点：${remain}米`;
      // 获取楼层名称
      var gname = this.getFloorNameByGid(data.level);
      var floorDom = document.getElementById("floor");
      floorDom.innerHTML = gname;
    },
  },
  created() {},
  mounted() {
    this.initMap();
    let _this = this;
    window.setStart = _this.setStart;
    window.setEnd = _this.setEnd;
    // 清空导航类中的起始点标注, 定位标注及当前路线标注。
  },
  updated() {},
};
</script>

<style lang="less" scoped>
#fengmap {
  width: 100vw;
  height: 100vh;
}
.bottom_box {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0.2rem 0.3rem;
  background: #fff;
  box-shadow: 0 13px 10px 10px rgb(198, 198, 198, 0.2);
  /deep/ .van-cell {
    background: #f4f4f4;
  }
  /deep/.van-cell-group {
    border-radius: 0.1rem;
    overflow: hidden;
    position: relative;
  }
  > .btn-box {
    padding-top: 0.12rem;
    justify-content: end;
    /deep/ .van-button--small {
      padding: 0 0.4rem;
    }
  }
}
.box {
  position: relative;
  width: 100%;
  height: 100%;
}
.sort_box {
  position: absolute;
  top: 50%;
  right: 0.02rem;
  transform: translate(-50%, -50%);
}
.pop-box {
  width: 100%;
  height: 100%;
  padding-top: 0.8rem;
  flex-direction: column;
  /deep/.van-search__content {
    border-radius: 0.1rem;
  }
  > .search_box {
    width: 100%;
    height: 1rem;
  }
}

.der_box {
  position: absolute;
  height: 2px;
  width: 80%;
  top: 50%;
  left: 0.58rem;
  transform: translate(0, -50%);
  background: #fff;
}
.pop-content {
  position: relative;
  flex: 1;
  width: 100%;
  margin-top: 0.4rem;
  overflow-x: hidden;
  /deep/ .van-index-bar__sidebar {
    bottom: 0;
  }
}
.panel-box {
  position: absolute;
  left: 0.2rem;
  top: 0.8rem;
  font-size: 0.24rem;
}
</style>
